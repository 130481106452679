import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface CommunitySafetyAndCleanliness {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      area: string;
      worker_id: number;
      status: string;
      created_at: string;
      latitude: number;
      longitude: number;
      worker: string;
      community_supply_items: {
        id: string;
        type: string;
        attributes: {
          id: number;
          supplied_item_id: number;
          count: number;
          value: number | null;
          created_at: string;
          supply_item_name: string;
          supply_item_type: string;
        };
      }[];
    };
  };
}

export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
interface Item {
  id: string;
  name: string;
  count: number ;
  checked: boolean;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  selectedService: string;
  items: Item[];
  selectedItems: string[];
  estimatedTotal: number;
  open: boolean;
  selectedFiles: File[];
  labelTextFirst: string;
  labelTextSecond: string;
  NumberOfItems: string;
  isModalOpen: boolean;
  imagesWeb: File[];
  baseImages: string[];
  base64: "",
  uploadedImage:number[],
  token:string,
  collapsed:boolean,
  image_ids: number[],
  formId: string;
  formType: string;
  communityFormDetails: CommunitySafetyAndCleanliness,
  showEdit: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  apiGetServiceCallId: string = "";
  apiSubmitCallId: string="";
  addImageApiCallId: string="";
  uploadImageCallId: string="";
  apiCallIdGetCustomFormDetails: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      selectedService: "",
      selectedItems: [],
      items: [],
      estimatedTotal: 0,
      open: false,
      selectedFiles: [],
      labelTextFirst: "Items",
      labelTextSecond: "List Items",
      NumberOfItems: "Number of Items:",
      isModalOpen: false,
      imagesWeb: [],
      baseImages: [],
      base64: '',
      uploadedImage:[],
      token:"",
      collapsed:true,
      image_ids: [],
      formId: "",
      formType: "",
      communityFormDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            area: "",
            worker_id: 0,
            status: "",
            created_at: "",
            latitude: 0,
            longitude: 0,
            worker: "",
            community_supply_items: []
          }
        }
      },
      showEdit: false
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.apiGetServiceCallId:
            this.setState({ items: responseJson.items }, () => this.addNewField());
            break;
  
          case this.apiSubmitCallId:
            if (responseJson.message) {
              this.setState({ isModalOpen: true });
            }
            break;
  
          case this.uploadImageCallId:
            this.handleImageUpload(responseJson);
            break;

          case this.apiCallIdGetCustomFormDetails:
            this.handleCustomFormDetails(responseJson)
            break;

          default:
            break;
          }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getToken()
    this.getUserList();
    const formid = await getStorageData("formId");
    const formtype = await getStorageData("formType");
    const edit = await getStorageData("showEdit");
    this.setState({
      formId: formid,
      formType: formtype,
      showEdit: edit
    });
    if(this.state.showEdit) {
      this.getFormDetails()
    }
  };

  handleCustomFormDetails = (responseJson: CommunitySafetyAndCleanliness) => {
    this.setState({ communityFormDetails: responseJson }, () => {
      if (this.state.formId && this.state.showEdit) {
        const apiItems = this.state.communityFormDetails?.data?.attributes?.community_supply_items.map((item) => ({
          id: item.id.toString(),
          name: item.attributes.supply_item_name,
          count: item.attributes.count,
          checked: true, 
        }));

        const mergedItems = [
          ...apiItems,
          ...this.state.items.filter(
            (item) => !apiItems.some((apiItem) => apiItem.id === item.id)
          ),
        ];

        this.setState({ items: mergedItems });
      }
    });
  }

  getFormDetails = async () => {

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
       token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetCustomFormDetails = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_custom_forms/update_submitted_forms/${this.state.formId}?form_type=${this.state.formType}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.apiCallIdGetCustomFormDetails;
};

  updateCount(event: ChangeEvent<HTMLInputElement>, id: string) {
    let value = event.target.value as unknown as number

    this.setState((prevState) => ({
      items: prevState.items?.map(item => item.id === id
        ? { ...item, count: value } 
        : item)
     
    }), () => this.updateEstimatedTotal);


  }
  addNewField = () => {
    this.setState((prevState) => ({
      items: prevState.items?.map(item => ({
        ...item,
        count: 0,
        checked: false 
      }))
    }));
  };
  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  onAddBtnClicked = () => {
    this.setState({ openDialogName: "Add" });
  };

  onFileChange = (file: File | null) => {
    this.setState({ file });
    if (this.state.openDialogName === "Edit") {
      this.setState({ isChange: true });
    }
  };

  onChangeHandler = (page: number) => {
    let { rowsPerPage, filterData } = this.state;
    let data = filterData;
    const dataLength = data.length;
    let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
    page = totalPage === page ? page - 1 : page;
    data = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.setState({ data, dataLength, page, loading: false });
  };

  onEditBtnClicked = (item: Data) => {
    this.setState({
      userId: item.userId,
      firstName: item.firstName,
      lastName: item.lastName,
      phoneNumber: item.phoneNumber,
      email: item.email,
      organization: item.organization,
      teamName: item.teamName,
      userType: item.userType,
      rating: item.rating,
      gender: item.gender,
      address: item.address,
      country: item.country,
      state: item.state,
      city: item.city,
      file: item.file,
      openDialogName: "Edit",
    });
  };

  onCancel = () => {
    this.setState({
      openDialogName: "",
      userId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: "",
    });
  };

  getUserList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onAddTask = () => {
    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("accessToken"),
    };

    const formData = new FormData();
    formData.append("form[first_name]", this.state.firstName);
    formData.append("form[last_name]", this.state.lastName);
    formData.append("form[phone_number]", this.state.phoneNumber);
    formData.append("form[organization]", this.state.organization);
    formData.append("form[team_name]", this.state.teamName);
    formData.append("form[i_am]", this.state.userType);
    formData.append("form[gender]", this.state.gender);
    formData.append("form[stars_rating]", this.state.rating);
    formData.append("form[email]", this.state.email);
    formData.append("form[address]", this.state.address);
    formData.append("form[country]", this.state.country);
    formData.append("form[state]", this.state.state);
    formData.append("form[city]", this.state.city);
    formData.append("form[file]", this.state.file as Blob);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addUserApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onEditTask = () => {
    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("accessToken"),
    };

    const formData = new FormData();
    formData.append("form[first_name]", this.state.firstName);
    formData.append("form[last_name]", this.state.lastName);
    formData.append("form[phone_number]", this.state.phoneNumber);
    formData.append("form[organization]", this.state.organization);
    formData.append("form[team_name]", this.state.teamName);
    formData.append("form[i_am]", this.state.userType);
    formData.append("form[gender]", this.state.gender);
    formData.append("form[stars_rating]", this.state.rating);
    formData.append("form[email]", this.state.email);
    formData.append("form[address]", this.state.address);
    formData.append("form[country]", this.state.country);
    formData.append("form[state]", this.state.state);
    formData.append("form[city]", this.state.city);
    if (this.state.isChange) {
      formData.append("form[file]", this.state.file as Blob);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editUserApiEndPoint}/${this.state.userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleToggle = (index: number) => {
    const { items } = this.state;
    if(items)
    items[index].checked = !items[index].checked;
    const selectedItems = items?.filter((item) => item.checked)
      .map((item) => item.name);

    this.setState({ items, selectedItems }, this.updateEstimatedTotal);
  };

  handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    this.state.items?.forEach((data) => {
      if (data.checked && data.name === event.target.value as string) {
        const selectedItems = event.target.value as string[];
        this.setState({ selectedItems }, this.updateEstimatedTotal);
      }
    })

  };

  updateEstimatedTotal = () => {

    const { items } = this.state;
    const total = items?.filter((item) => item.checked)
      .reduce((sum: number, item: Item) => sum + Number(item.count), 0);
    this.setState({ estimatedTotal: total });
  };

  handleOpen = () => {
    this.setState({ open: true });

  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
   
    if (files) {
      this.setState((prevState) => ({
        selectedFiles: [...prevState.selectedFiles, ...Array.from(files)],
      }));
    }
  };

  handleChange = (event: SelectChangeEvent<string>) => {
    const selectedService = event.target.value;
    let labelTextFirst = 'Items';
    let labelTextSecond = 'List Items';
    let NumberOfItems = 'Number of Items:';

    switch (selectedService) {
      case 'serviceCompleted':
        labelTextFirst = 'Items Picked Up';
        labelTextSecond = 'List Items Picked Up';
        NumberOfItems = 'Total Number of Items:';
        break;

      case 'requestService':
        labelTextFirst = 'Items Needing Retrieval';
        labelTextSecond = 'List Items Needing Retrieval';
        NumberOfItems = 'Estimated Number of Items:';
        break;
    }

    this.setState({ selectedService, labelTextFirst, labelTextSecond, NumberOfItems });
  };
  getSuppliedItems =  () => {


    const header = {
      "Content-Type": "application/json",
      token:this.state.token
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetServiceCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSuppliedItemsapiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fetchFormDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  goback = async (page: string) => {
    await removeStorageData("showEdit");
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
  submitForm =  async () => {
    await removeStorageData("showEdit");
    const newArray = this.state.items?.filter(item => item.checked)
      .map(item => ({
        supplied_item_id: item.id,
        count: item.count
      }));

    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    }

    const data = {
      area: this.state.selectedService,
      lat: 28.6129,
      lng: 77.2050245803,
      image_ids: this.state.image_ids,
      "community_supply_items_attributes": newArray
    }

    const httpBody = {
      data: data
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSubmitCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subitCleanlinessForm
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.formAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  handleCloseModal = () => {

    this.setState({ isModalOpen: false });
    this.goback("LandingPage")
  };

 
  getToken = async () => {
    const token = await getStorageData("token")
    this.setState({token:token},()=> this.getSuppliedItems())
  };
  uploadImages = () => {
    const { selectedFiles } = this.state;
  
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("blob[]", new File([file as any], file.name, { type: "image/png" })); 
    });
  
    const headers = {
      token: this.state.token,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadImageCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addPictureEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.formAPiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleImageUpload = (responseJson:{message:string,blobs:any}) => {
    if (responseJson && responseJson.message === "Images uploaded successfully") {  
      const uploadedBlob = responseJson.blobs;
      const uploadedImageId = responseJson.blobs.map((blob: { id: any; }) => blob.id)
      this.setState((prevState) => ({
        image_ids:  uploadedImageId,
        selectedFiles: prevState.selectedFiles.map((file) =>
          file.name === uploadedBlob.filename ? { ...file, status: "uploaded" } : file
        ),
       
      }));
      this.setState({open:false})
    } 
    

  }
  handleCountChange = (event: { target: { value: any; }; }, itemName: string) => {
    const updatedItems = this.state.items.map((item) =>
      item.name === itemName ? { ...item, count: event.target.value } : item
    );
    this.setState({ items: updatedItems },()=>this.updateEstimatedTotal());
  };
  // Customizable Area End
}
